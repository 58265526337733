import { useContext } from 'react';
import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { RelationshipNotesModalContext } from '../RelationshipNotesModalContext';

interface CloseIconButtonProps {
  onClick: () => void;
  'data-cy': string;
  label: string;
}

export function CloseIconButton({
  onClick,
  'data-cy': dataCy,
  label,
}: CloseIconButtonProps) {
  const { setOnConfirmDiscardNoteChanges } = useContext(
    RelationshipNotesModalContext,
  );

  const handleClick = (callback: () => void) => {
    setOnConfirmDiscardNoteChanges(() => callback);
  };

  return (
    <IconButton onClick={() => handleClick(onClick)}>
      <Close aria-label={label} data-cy={dataCy} />
    </IconButton>
  );
}
