//external
import { Box, Card, Modal, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo } from 'react';

//types
import { IRelationship } from '@guider-global/shared-types';

//components
import {
  RelationshipNotesCreate,
  RelationshipNotesEmpty,
  RelationshipNotesList,
  RelationshipNotesView,
} from 'components';
import { CloseIconButton } from './CloseIconButton';

//hooks
import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { useLocalization, useMobileMediaQuery } from 'hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface IRelationshipNotesModalProps {
  relationship: IRelationship;
  isOpen: boolean;
  handleClose: () => void;
}

export const RelationshipNotesModal: React.FC<IRelationshipNotesModalProps> = ({
  relationship,
  isOpen,
  handleClose,
}) => {
  //hooks
  const organizationSlug = getSubDomain();

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  //style
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  const navigate = useNavigate();
  const { noteId } = useParams();
  const { pathname } = useLocation();

  const relationshipNotes = relationship?.notes ?? [];
  const noRelationshipNotes = relationshipNotes.length === 0;

  const isNoteCreation = useMemo(() => {
    return pathname.includes('/create');
  }, [pathname]);

  useEffect(() => {
    if (
      isOpen &&
      !isMobile &&
      !isNoteCreation &&
      !noRelationshipNotes &&
      !noteId
    ) {
      navigate(
        `/relationships/${relationship.id}/notes/${relationshipNotes?.at(0)
          ?.id}`,
      );
    }
  });

  const renderContent = () => {
    let Component: React.FC | null = null;

    if (isNoteCreation) {
      Component = () => <RelationshipNotesCreate relationship={relationship} />;
    } else if (noteId) {
      Component = () => <RelationshipNotesView relationship={relationship} />;
    } else if (noRelationshipNotes) {
      Component = () => <RelationshipNotesEmpty />;
    }

    if (isMobile) {
      return (
        (Component && <Component />) || (
          <RelationshipNotesList notes={relationshipNotes} />
        )
      );
    }

    return (
      <>
        <RelationshipNotesList notes={relationshipNotes} />
        {Component && <Component />}
      </>
    );
  };

  //component
  return (
    <Modal open={isOpen} onClose={handleClose} disableEscapeKeyDown>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: { xs: '100vh', md: '75vh' },
          minHeight: { xs: '100vh', md: '75vh' },
          width: { xs: '100vw', md: '80vw' },
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderTop: `8px solid ${theme.palette.secondary.main}`,
          ...(isMobile && { borderRadius: '0px' }),
          overflowY: 'auto',
        }}
        data-cy="relationship-notes-modal"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: { xs: 2, md: 3 },
            py: 2,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <Typography
            variant="h6"
            component="p"
            data-cy="relatioship-notes-title"
          >
            {
              baseLanguage?.relationships?.notes?.notes_modal?.notes_common
                ?.notes_common_title
            }
          </Typography>
          <CloseIconButton
            onClick={handleClose}
            data-cy="relationship-notes-modal-close-icon-button"
            label={baseLanguage?.globals?.common?.close_button_label ?? 'Close'}
          />
        </Box>
        <Box sx={{ display: 'flex', flex: 1 }}>{renderContent()}</Box>
      </Card>
    </Modal>
  );
};
